import clsx from 'clsx';
import { AnchorHTMLAttributes, ButtonHTMLAttributes } from 'react';

const buttonStyles =
  'py-2.5 px-4 rounded-[38px] button bg-button hover:bg-button-hover transition-[background-position] duration-200 inline-flex items-baseline space-x-2 flex-nowrap whitespace-nowrap';

const buttonLinkStyles =
  'py-2.5 px-4 hover:text-white/60 transition-colors inline-flex items-baseline space-x-2 flex-nowrap whitespace-nowrap';

type ButtonProps = {
  left?: React.ReactNode;
  right?: React.ReactNode;
  className?: string;
  classNameOverride?: string;
  contentClassName?: string;
  children: React.ReactNode;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const Button = ({
  className,
  classNameOverride,
  contentClassName,
  left,
  right,
  children,
  ...props
}: ButtonProps) => (
  <button
    className={classNameOverride || clsx(buttonStyles, className)}
    {...props}
  >
    {left && <span className="inline-flex self-center">{left}</span>}
    <span className={contentClassName ?? ''}>{children}</span>
    {right && <span className="inline-flex self-center">{right}</span>}
  </button>
);

export const ButtonLink = ({
  className,
  classNameOverride,
  left,
  right,
  children,
  ...props
}: ButtonProps) => (
  <button
    className={classNameOverride || clsx(buttonLinkStyles, className)}
    {...props}
  >
    {left && <span className="inline-flex self-center">{left}</span>}
    <span>{children}</span>
    {right && <span className="inline-flex self-center">{right}</span>}
  </button>
);

type LinkButtonProps = {
  left?: React.ReactNode;
  right?: React.ReactNode;
  className?: string;
  classNameOverride?: string;
  children: React.ReactNode;
} & AnchorHTMLAttributes<HTMLAnchorElement>;

export const LinkButton = ({
  left,
  right,
  className,
  classNameOverride,
  children,
  ...props
}: LinkButtonProps) => (
  <a className={classNameOverride || clsx(buttonStyles, className)} {...props}>
    {left && <span className="inline-flex self-center">{left}</span>}
    <span>{children}</span>
    {right && <span className="inline-flex self-center">{right}</span>}
  </a>
);

export default Button;
