import clsx from 'clsx';
import phoneFrame from '../../images/frame@2x.png';
import Image from 'next/image';

const Phone = ({
  children,
  className,
  glow = true,
}: {
  children: React.ReactNode;
  className?: string;
  glow?: boolean;
}) => (
  <div
    className={clsx(
      'max-w-[255px] md:max-w-[350px] lg:max-w-[414px] relative aspect-[1/2]',
      className
    )}
  >
    {glow ? (
      <div className="shadow-phone-glow rounded-t-[60px] absolute top-0 left-0 right-0 bottom-0 w-[95%] h-[90%] m-auto"></div>
    ) : null}
    <Image
      priority
      src={phoneFrame}
      alt=""
      className="absolute top-0 left-0 right-0 bottom-0 w-full h-auto pointer-events-none"
    />
    <div className="relative h-full pt-[4%] py-[2%] px-[4%]">
      <div className="rounded-[24px] sm:rounded-[40px] h-full overflow-hidden isolate">
        {children}
      </div>
    </div>
  </div>
);

export default Phone;
