import Wrapper from './common/Wrapper';
import {
  DOCS_URL,
  GITHUB_URL,
  MOBILE_PRIVACY_POLICY_URL,
  PRIVACY_POLICY_URL,
  ToS_URL,
  TWITTER_URL,
} from '../shared/constants';
import Link from './common/Link';
import clsx from 'clsx';

const Footer = ({ shrinked }: { shrinked?: boolean }) => (
  <footer
    className={clsx(
      'flex flex-col relative  overflow-hidden',
      shrinked ? 'py-6' : 'py-16'
    )}
  >
    <Wrapper className="text-secondary-200 flex flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-14  items-center lg:items-baseline justify-center relative z-10">
      <Link target="_blank" rel="noreferrer" href={GITHUB_URL}>
        Github
      </Link>
      <Link target="_blank" rel="noreferrer" href={DOCS_URL}>
        Gitbook
      </Link>
      <Link target="_blank" rel="noreferrer" href={TWITTER_URL}>
        Twitter
      </Link>
      <Link target="_blank" rel="noreferrer" href={ToS_URL}>
        Terms of Service
      </Link>
      <Link target="_blank" rel="noreferrer" href={PRIVACY_POLICY_URL}>
        Privacy Policy
      </Link>
      <Link target="_blank" rel="noreferrer" href={MOBILE_PRIVACY_POLICY_URL}>
        Mobile Privacy Policy
      </Link>
    </Wrapper>
  </footer>
);

export default Footer;
