import DialectAppLogo from '../icons/DialectApp.svg';
import Wrapper from './common/Wrapper';
import Link from './common/Link';
import React from 'react';

const Header = ({
  logo = <DialectAppLogo className="h-[36px] lg:h-[54px]" />,
  logoTag,
  CTA,
  links,
}: {
  CTA?: React.ReactNode;
  logo?: React.ReactNode;
  logoTag?: React.ReactNode;
  links?: React.ReactNode;
}) => {
  return (
    <Wrapper>
      <div className="flex flex-row justify-between items-center py-8 relative z-10">
        <Link href="https://dialect.to" className="flex flex-row">
          {logo}
          {logoTag}
        </Link>
        <div className="flex items-center lg:items-baseline space-x-4 lg:space-x-8">
          {links}
          <div>{CTA}</div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Header;
