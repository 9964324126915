import Image from 'next/image';
import BubblePng from '../../icons/Bubble@2x.png';
import BubbleSvg from '../../icons/Bubble.svg';

const Bubble = ({ className }: { className?: string }) => (
  <Image
    priority
    unoptimized={true}
    src={BubblePng}
    alt=""
    className={className}
  />
  // <BubbleSvg className={className} />
  // TODO: check how to render svg smoothly in safari
);

export default Bubble;
