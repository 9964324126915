import { useContext, useEffect, useState } from 'react';
import MicroModal from 'react-micro-modal';
import { QRCodeContext } from './context/QRCodeContext';
import DialectLogo from '../icons/DialectApp.svg';
import DownloadQRCode from '../icons/qr-code.svg';
import { useRouter } from 'next/router';
import { useQRCode } from 'next-qrcode';
import { ParsedUrlQuery } from 'querystring';
import { DIALECT_CLOUD_API } from '../shared/constants';

function getQueryValue(queryObject: ParsedUrlQuery, queryKey: string): string {
  const queryValue = queryObject[queryKey] ?? '';

  if (Array.isArray(queryValue)) {
    return '';
  }

  return queryValue;
}

const QRCodeModal = () => {
  const { open, setOpen, modalEnabled } = useContext(QRCodeContext);

  const router = useRouter();
  const query = router.query;

  const [referralLink, setReferralLink] = useState<string | null>(null);
  useEffect(function fetchReferralLinkByWalletAddress() {
    async function fetchData(param: string) {
      const referralLink = await fetch(
        `${DIALECT_CLOUD_API}/api/v1/referrers/search/?walletAddress=${param}`
      )
        .then((response) => response.json())
        .then((parsedResponse: { link: string }) => parsedResponse.link)
        .catch(() => {
          return null;
        });

      setReferralLink(referralLink);
    }

    const walletAddress = getQueryValue(query, 'wallet');

    if (walletAddress) {
      fetchData(walletAddress);
    }
  }, []);

  const { Canvas } = useQRCode();

  return modalEnabled ? (
    <MicroModal
      open={open}
      handleClose={() => setOpen(false)}
      closeOnEscapePress={true}
      closeOnOverlayClick={true}
      overrides={{
        Root: {
          className: 'z-[150]',
        },
        Overlay: {
          className: 'z-[150]',
        },
        Dialog: {
          className:
            'w-[300px] h-auto !bg-[#160033] !rounded-[20px] animate-bounce',
          style: { zIndex: 150 },
        },
      }}
    >
      {(handleClose) => (
        <div className="text-white text-center flex flex-col items-center justify-center space-y-2">
          <DialectLogo className="h-[24px]" />

          {referralLink ? (
            <Canvas
              text={referralLink}
              options={{
                level: 'Q',
                margin: 2,
                scale: 5,
                width: 200,
                color: {
                  dark: '#ffffff',
                  light: '#160033',
                },
              }}
            />
          ) : (
            <DownloadQRCode className="w-[200px]" />
          )}

          <p className="text-secondary-200">
            Scan the QR code above to search for the <b>Dialect</b> app in your
            device&apos;s app store.
          </p>
        </div>
      )}
    </MicroModal>
  ) : null;
};

export default QRCodeModal;
