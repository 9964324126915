import clsx from 'clsx';

const Wrapper = ({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) => (
  <div className={clsx('w-full lg:max-w-7xl mx-auto px-4 lg:px-8', className)}>
    {children}
  </div>
);

export default Wrapper;
