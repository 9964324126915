import Image from 'next/image';
import BubblePng from '../../icons/DevBubble@2x.png';
import BubbleSvg from '../../icons/Bubble.svg';

const DevBubble = ({ className }: { className?: string }) => (
  <Image
    priority
    unoptimized={true}
    src={BubblePng}
    alt=""
    className={className}
  />
  // TODO: check how to render svg smoothly in safari
);

export default DevBubble;
