const LogoTag = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="align-super mt-0.5 ml-0.5">
      <div className="rounded-[2px] bg-[#171717] text-[#25BC3B] text-xs sm:text-[13px] py-0.25 px-1">
        {children}
      </div>
    </div>
  );
};

export default LogoTag;
