import clsx from 'clsx';

import Wrapper from './common/Wrapper';

const CTA = ({
  title,
  className,
  CTA,
}: {
  title: string;
  className?: string;
  CTA?: React.ReactNode;
}) => {
  return (
    <Wrapper className={clsx(className)}>
      <div className="py-20 px-8 sm:px-20 lg:px-32 lg:py-32">
        <div className="flex flex-col space-y-8 justify-between items-center py-8">
          <div className="lg:basis-3/6 text-center lg:text-left flex flex-col">
            <h2 className="text-[25px] lg:text-[56px] tracking-tighter leading-none font-semibold text-transparent text-center bg-gradient-to-b from-[#D4B5FD] to-[#B987FC] bg-clip-text pb-1">
              {title}
            </h2>
          </div>
          <div className="lg:basis-3/6 flex space-x-4 items-center lg:justify-end justify-center">
            {CTA}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default CTA;
