import Head from 'next/head';
import Image from 'next/image';
import Card from '../components/Card';
import Footer from '../components/Footer';
import Header from '../components/Header';
import QRCodeModal from '../components/QRCodeModal';
import DOLLARS from '../icons/DOLLARS.png';
import CTA from '../components/CTA';
import { LinkButton } from '../components/common/Button';
import DevHero from '../components/DevHero';
import GrantIcon from '../icons/grant.svg';
import GithubIcon from '../icons/Github.svg';
import GitbookIcon from '../icons/Gitbook.svg';
import {
  CDN_URL,
  DOCS_URL,
  GITHUB_URL,
  GRANT_APPLICATION_URL,
  smartMessageDemoVideo,
  SPEC_URL,
} from '../shared/constants';
import LoopedVideo from '../components/common/LoopedVideo';
import LogoTag from '../components/common/LogoTag';
import { H2, P1 } from '../components/common/primitives';

export default function Developers() {
  const grantProgram = (
    <LinkButton
      href={GRANT_APPLICATION_URL}
      target="_blank"
      left={<GrantIcon className="self-center" />}
    >
      Grant Program
    </LinkButton>
  );

  const mainCTA = (
    <p className="flex flex-wrap gap-x-2 gap-y-3 justify-center lg:justify-start">
      {grantProgram}
      <LinkButton
        className="hidden sm:inline-flex"
        href={GITHUB_URL}
        target="_blank"
        left={<GithubIcon className="self-center" />}
      >
        Github
      </LinkButton>
      <LinkButton
        className="hidden sm:inline-flex"
        href={DOCS_URL}
        target="_blank"
        left={<GitbookIcon className="self-center" />}
      >
        Docs
      </LinkButton>
    </p>
  );

  const footerCTA = (
    <p className="flex flex-wrap gap-x-2 gap-y-3 justify-center lg:justify-start">
      {grantProgram}
      <LinkButton
        href={GITHUB_URL}
        target="_blank"
        left={<GithubIcon className="self-center" />}
      >
        Github
      </LinkButton>
      <LinkButton
        href={DOCS_URL}
        target="_blank"
        left={<GitbookIcon className="self-center" />}
      >
        Docs
      </LinkButton>
    </p>
  );

  const title = 'Dialect | Developers';
  const description =
    'Aquire, engage, & retain users with smart messages, dApp notifications, wallet-to-wallet chat, & more using our open source tools.';
  const linkPreview = `${CDN_URL}/link-previews/developers-link-preview.png`;

  return (
    <div>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="twitter:title" content={title} />
        <meta property="og:title" content={title} />
        <meta property="twitter:description" content={description} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={linkPreview} />
        <meta property="twitter:image" content={linkPreview} />
      </Head>

      <main className="text-white overflow-hidden">
        <Header
          logoTag={<LogoTag>developers</LogoTag>}
          CTA={<div className="hidden sm:block">{grantProgram}</div>}
        />
        <DevHero className={'mb-24 lg:mb-28'} CTA={mainCTA} />
        <Card
          className="mb-20"
          phoneContent={<LoopedVideo src={smartMessageDemoVideo} />}
        >
          <H2>The Smart Messaging Spec is Live</H2>
          <P1>
            Smart messages are a new, interactive way to text. Send and receive
            tokens, share mints, or anything else.
          </P1>
          <p>
            <LinkButton
              href={SPEC_URL}
              target="_blank"
              left={<GitbookIcon className="self-center" />}
            >
              View spec
            </LinkButton>
          </p>
        </Card>
        <Card
          phoneOnRight
          className="mb-20"
          content={<Image className="object-contain" alt="" src={DOLLARS} />}
        >
          <H2>$100k Grant Program</H2>
          <P1>
            We are offering $100,000 in ecosystem grants to support
            next-generation chat experiences.
          </P1>
          <p>{grantProgram}</p>
        </Card>
        <CTA
          title={
            'Join 50+ projects developing the first Smart Messages, including Magic Eden, Helium, Jupiter, Realms, Squads, and many others.'
          }
          CTA={footerCTA}
        />
        <Footer />
        <QRCodeModal />
      </main>
    </div>
  );
}
