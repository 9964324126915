import clsx from 'clsx';
import { AnchorHTMLAttributes } from 'react';

type LinkProps = {
  active?: boolean;
} & AnchorHTMLAttributes<HTMLAnchorElement>;

const Link = ({ className, active, ...props }: LinkProps) => (
  <a
    className={clsx(
      'hover:text-white/60 transition-colors',
      active && 'bg-link-active-gradient text-transparent bg-clip-text',
      className
    )}
    {...props}
  />
);
export default Link;
