import Wrapper from './common/Wrapper';
import Image from 'next/image';

import DevBubble from './common/DevBubble';
import { H1, P1 } from './common/primitives';

const DevHero = ({
  className,
  CTA,
}: {
  className?: string;
  CTA?: React.ReactNode;
}) => {
  return (
    <Wrapper className={className}>
      <div className="flex flex-col lg:flex-row space-y-6 lg:space-y-0 justify-between items-center px-8 lg:px-0 py-8">
        <div className="w-full lg:order-1 lg:basis-3/6 flex items-center justify-center">
          <div className="relative w-2/5 lg:mr-24">
            <DevBubble className="absolute max-w-none w-auto h-[130%] top-0 bottom-0 left-0 right-0 -translate-y-[10%] lg:-translate-y-[10vh] -translate-x-[35%]" />
            <div className="relative lg:w-auto lg:max-w-none lg:h-[75vh] z-10 bg-red-7002" />
          </div>
        </div>
        <div className="lg:basis-3/6 relative z-10">
          <div className="text-center lg:text-left flex items-center lg:items-start flex-col space-y-6 lg:space-y-8 max-w-[470px]">
            <H1>Your message, delivered</H1>
            <P1>
              Dialect is the first protocol for smart messaging — dynamic,
              composable dapp notifications and wallet-to-wallet chat.
            </P1>
            {CTA}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default DevHero;
