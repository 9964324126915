import { FunctionComponent, DetailedHTMLProps, HTMLAttributes } from 'react';
import clsx from 'clsx';

type HeaderProps = DetailedHTMLProps<
  HTMLAttributes<HTMLHeadingElement>,
  HTMLHeadingElement
>;
type ParagraphProps = DetailedHTMLProps<
  HTMLAttributes<HTMLParagraphElement>,
  HTMLParagraphElement
>;

export function H1({ className, ...props }: HeaderProps) {
  return (
    <h1
      className={clsx(
        'text-[45px] lg:text-[63px] leading-none tracking-tighter font-bold text-transparent bg-clip-text bg-text-gradient',
        className
      )}
      {...props}
    />
  );
}

export function H2({ className, ...props }: HeaderProps) {
  return (
    <h2
      className={clsx(
        'text-[37px] lg:text-[47px] leading-none tracking-tighter font-bold text-transparent bg-text-gradient bg-clip-text',
        className
      )}
      {...props}
    />
  );
}

export function H4({ className, ...props }: HeaderProps) {
  return (
    <h4
      className={clsx(
        'text-[1.75rem] leading-none tracking-tighter font-bold text-secondary-200',
        className
      )}
      {...props}
    />
  );
}

export function P1({ className, ...props }: ParagraphProps) {
  return (
    <p
      className={clsx(
        'text-[15px] lg:text-[23px] leading-snug tracking-tight text-secondary-200',
        className
      )}
      {...props}
    />
  );
}

export const Ol: FunctionComponent<{
  className?: string;
  children: React.ReactNode;
}> = ({ className, children }) => (
  <ol className={clsx('list-disc list-inside mb-4', className)}>{children}</ol>
);

export const Ul: FunctionComponent<{
  className?: string;
  children: React.ReactNode;
}> = ({ className, children }) => (
  <ul className={clsx('list-disc list-inside mb-4', className)}>{children}</ul>
);

export const Li: FunctionComponent<{
  className?: string;
  children: React.ReactNode;
}> = ({ children }) => (
  <li className="text-[15px] lg:text-[23px] leading-relaxed">{children}</li>
);

export const Pa: FunctionComponent<{
  className?: string;
  children: React.ReactNode;
}> = ({ children }) => <P1 className="text-left mb-4">{children}</P1>;

export const Bold: FunctionComponent<{
  className?: string;
  children: React.ReactNode;
}> = ({ children }) => <span className="font-bold">{children}</span>;

export const Heading: FunctionComponent<{
  className?: string;
  children: React.ReactNode;
}> = ({ className, children }) => (
  <H2 className={clsx('text-left mt-10 mb-4', className)}>{children}</H2>
);

export const Subheading: FunctionComponent<{
  className?: string;
  children: React.ReactNode;
}> = ({ className, children }) => (
  <H4 className={clsx('text-left mt-6 mb-4', className)}>{children}</H4>
);
