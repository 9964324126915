export const DOCS_URL = 'https://docs.dialect.to';
export const SPEC_URL = 'https://github.com/dialectlabs/smart-messaging';
export const GITHUB_URL = 'https://github.com/dialectlabs';
export const DISCORD_URL = 'https://discord.gg/7zUFzTAP';
export const TWITTER_URL = 'https://twitter.com/saydialect';
export const APP_URL = 'https://app.dialect.to/app';
export const PRIVACY_POLICY_URL = 'https://www.dialect.to/privacy';
export const MOBILE_PRIVACY_POLICY_URL =
  'https://www.dialect.to/mobile-privacy';
export const ToS_URL = 'https://www.dialect.to/tos';
export const GRANT_APPLICATION_URL =
  'https://docs.google.com/forms/d/1hoFd4Yd_ka4DSoR_yS2rvJe6WWp-YjbaPv9_2G3AJok/viewform?ts=6362b4a2&edit_requested=true';

export const CDN_URL =
  'https://dialect-file-storage.s3.us-west-2.amazonaws.com';

export const superCutVideo = `${CDN_URL}/app-videos/super-cut.mp4`;
export const superCutPoster = `${CDN_URL}/app-videos/super-cut-poster.png`;

export const smartMessageDemoVideo = `${CDN_URL}/app-videos/pay-v2.mp4`;

export const notificationsDemoVideo = `${CDN_URL}/app-videos/exchange-art.mp4`;
export const notificationsDemoPoster = `${CDN_URL}/app-videos/exchange-art-poster.png`;

export const mintNftVideo =
  'https://dialect-file-storage.s3.us-west-2.amazonaws.com/app-videos/mint.mp4';

export const mintWithRevealNftVideo =
  'https://dialect-file-storage.s3.us-west-2.amazonaws.com/app-videos/mint-v2.mp4';

export const DIALECT_CLOUD_API = 'https://dialectapi.to';

export const IOS_APP_ID = '1639348308';
export const APP_STORE_URL = `https://apps.apple.com/app/id${IOS_APP_ID}?mt=8`;

export const ANDROID_PACKAGE_NAME = 'to.dialect.app';
export const GOOGLE_PLAY_URL = `https://play.google.com/store/apps/details?id=${ANDROID_PACKAGE_NAME}`;

export const SHARE_DOMAIN_MOBILE_FALLBACK = 'share.dialect.to';
export const SHARE_DOMAIN = 'app.dialect.to';
