import clsx from 'clsx';
import Wrapper from './common/Wrapper';
import Bubble from './common/Bubble';
import CardBubble from './common/CardBubble';
import Phone from './common/Phone';

const Card = ({
  className,
  children,
  phoneOnRight,
  phoneContent,
  content,
  CTA,
}: {
  content?: React.ReactNode;
  phoneContent?: React.ReactNode;
  phoneOnRight?: boolean;
  className?: string;
  CTA?: React.ReactNode;
  children: React.ReactNode;
}) => {
  return (
    <Wrapper className={className}>
      <div className="w-full flex flex-col only:lg:flex-row justify-between items-center lg:h-[700px] relative overflow-hidden rounded-[20px] lg:rounded-[64px] rounded-safari-fix bg-[#1A0139]">
        <div
          className={clsx(
            'flex relative z-10 order-1 lg:basis-1/2 w-full h-full lg:px-[70px] lg:pt-[90px] justify-center',
            phoneOnRight ? 'lg:justify-end lg:order-1' : 'lg:-order-1'
          )}
        >
          {!content ? (
            <Phone className="-mb-[25%] lg:-mb-[52%]">{phoneContent}</Phone>
          ) : (
            content
          )}
        </div>
        <div className="lg:basis-1/2 h-full">
          <CardBubble
            className={clsx(
              'hidden lg:block absolute h-[120%] -top-[10%]',
              phoneOnRight ? '-left-[50%] scale-[-1]' : '-right-[50%]'
            )}
          />
          <div className="relative m-10 lg:my-0 sm:mx-[100px] lg:h-full">
            <Bubble className="block lg:hidden absolute max-w-none w-[200vw] -top-[50vw] sm:-top-[200%] -translate-x-[20%]" />
            <div className="flex flex-col space-y-6 justify-center text-center lg:text-left h-full relative z-10 min-h-[300px]">
              {children}
              {CTA}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Card;
