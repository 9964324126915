import clsx from 'clsx';
import Image from 'next/image';
import { useCallback, useEffect, useRef, useState } from 'react';
import Button, { ButtonLink } from './Button';

enum VideoState {
  NotInited = 'not-inited',
  Playing = 'playing',
  Paused = 'paused',
  Played = 'played',
}

const getState = (video: HTMLVideoElement) => {
  if (!video) {
    return VideoState.NotInited;
  }
  if (video.currentTime === video.duration) {
    return VideoState.Played;
  }
  if (video.paused) {
    return VideoState.Paused;
  }
  return VideoState.Playing;
};

const ActionMap: Record<VideoState, string> = {
  'not-inited': 'Loading ...',
  played: 'Replay',
  paused: 'Play',
  playing: 'Pause',
};

type LoopedVideo = {
  src: string;
  placeholderSrc?: string;
};

const LoopedVideo = ({ src, placeholderSrc }: LoopedVideo) => {
  const [visible, setVisible] = useState(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [playbackState, setPlaybackState] = useState(VideoState.NotInited);
  const action = ActionMap[playbackState];

  const handleOnTimeUpdate = useCallback(() => {
    const video = videoRef?.current;
    if (!video) {
      return;
    }
    setPlaybackState(getState(video));
  }, [videoRef]);

  useEffect(() => {
    const video = videoRef?.current;
    if (!video) {
      return;
    }
    video.src = src;
  }, [videoRef, src]);

  return (
    <>
      {/* TODO: add play/pause/replay button */}
      {/* <div className="absolute w-full left-0 right-0 translate-y-[calc(-100%-20px)] flex justify-center z-10">
        <ButtonLink
          className="text-sm text-white/50"
          onClick={() => {
            if (!videoRef?.current) {
              return;
            }
            if (playbackState === "playing") {
              videoRef.current.pause();
            }
            if (playbackState === "paused") {
              videoRef.current.play();
            }
          }}
        >
          {action}
        </ButtonLink>
      </div> */}
      <div className="relative">
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img alt="" src={placeholderSrc} className="absolute" />
        <video
          role="img"
          ref={videoRef}
          className={clsx(
            'relative z-10',
            visible ? 'opacity-100' : 'opacity-0'
          )}
          poster={placeholderSrc}
          onPlay={() => {
            setTimeout(() => setVisible(true), 100);
          }}
          onTimeUpdate={handleOnTimeUpdate}
          autoPlay
          loop
          muted
          playsInline
        />
      </div>
    </>
  );
};

export default LoopedVideo;
